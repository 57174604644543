var form = $('.js-form-bid');
var fieldName = $('.js-form-bid .js-name');
// var fieldPhone = $('.js-form-bid .js-phone');

var fieldEmail = $('.js-form-bid .js-email');
var fieldText = $('.js-form-bid #cbText');
var fieldAccept = $('.js-form-bid .js-accept');
var fieldPageId = $('.js-form-bid .page_id');

var fields = $('.js-form-bid .form__control');

var submitButton = form.find('.s-submit');
var msgContainerName = ".js-message";
var msgContainer = $(msgContainerName);

// $("[name='phone']").inputmask({
//     mask: "+7 (9##) ###-##-##",
//     autoUnmask: true,
//     showMaskOnHover: false
// });

form.validate({
    ignore: ":hidden:not(.input--ignore-not)",
    errorClass: "form__error",
    errorContainer: msgContainerName,
    errorElement: "span",
    errorLabelContainer: msgContainerName,
    showErrors: function (errorMap, errorList) {

        if (msgContainer.hasClass("jsCheckSendClass")) {
            msgContainer.html('').removeClass("jsCheckSendClass");
        }
        
        msgContainer.removeClass("form__alert--success").addClass("form__alert--error");
        this.defaultShowErrors();
    },
    
    rules: {
        name: {
            required: true,
            minlength: 2
        },
        // phone: {
        //     required: true,
        //     minlength: 10,
        // },
        email: {
            required: true,
            minlength: 2            
        },
        accept: {
            required: true
        }

    },
    messages: {
        name: {
            required: "Введите имя.<br>",
            minlength: "Имя должно состоять как минимум из 2-х символов.<br>"
        },
        // phone: {
        //     required: "Введите номер телефона.<br>",
        //     minlength: "Номер телефона должен состоять как минимум из 10-ти символов.<br>",
        // },
        email: "Введите адрес электронной почты или телефон.<br>",
        accept: "Требуется согласие с политикой конфиденциальности.<br>"
    },
    submitHandler: function () {
        var predata = {
            'name': fieldName.val(),
            'email': fieldEmail.val(),
            // 'phone': fieldPhone.val(),
            'message': fieldText.val(),
            'id': fieldPageId.val(),
            'accept': fieldAccept.val(),
            'type_form': $(form).attr('data-form-type')
        };

        $.ajax({
            type: "POST",
            url: "/ajax-handler/",
            data:  predata,
            success: function (data) {
                var response = $.parseJSON(data);
                showResponce(response['status']);

                $('input:checked').prop('checked', false);
                $(form).find(".form__control").val("");
            },
            error: function (data, textStatus, errorThrown) {
                Swal({
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось отправить сообщение',
                });      
            }
        });

        return false;
    }
});

// Form footer-------------------------------------------------------------------------------

var formFooter = $('.js-form-footer');
var fieldNameFooter = $('.js-form-footer .js-name');
var fieldEmailFooter = $('.js-form-footer .js-email');
var fieldTextFooter = $('.js-form-footer #cbText');
var fieldPageIdFooter = $('.js-form-footer .page_id');

var fields = $('.js-form-footer .form__control');

var submitButton = formFooter.find('.s-submit');
var msgContainerName = ".js-message";
var msgContainer = $(msgContainerName);

formFooter.validate({
    ignore: ":hidden:not(.input--ignore-not)",
    errorClass: "form__error",
    errorContainer: msgContainerName,
    errorElement: "span",
    errorLabelContainer: msgContainerName,
    showErrors: function (errorMap, errorList) {

        if (msgContainer.hasClass("jsCheckSendClass")) {
            msgContainer.html('').removeClass("jsCheckSendClass");
        }

        msgContainer.removeClass("form__alert--success").addClass("form__alert--error");
        this.defaultShowErrors();
    },

    rules: {
        name: {
            required: true,
            minlength: 2
        },
        email: {
            required: true,
            minlength: 2
        }
    },
    messages: {
        name: {
            required: "Введите имя.<br>",
            minlength: "Имя должно состоять как минимум из 2-х символов.<br>"
        },
        email: "Введите адрес электронной почты или телефон.<br>"
    },
    submitHandler: function () {
        var predata = {
            'name': fieldNameFooter.val(),
            'email': fieldEmailFooter.val(),
            'message': fieldTextFooter.val(),
            'id': fieldPageIdFooter.val(),
            'type_form': $(formFooter).attr('data-form-type'),
        };

        $.ajax({
            type: "POST",
            url: "/ajax-handler/",
            data: predata,
            success: function (data, textStatus, jqXHR) {
                var response = $.parseJSON(data);
                showResponce(response['status']);

                $(formFooter).find(".form__control").val("");
            },
            error: function (data, textStatus, errorThrown) {
                Swal({
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось отправить сообщение',
                });
            }
        });
        return false;
    }
});

// Form callback-------------------------------------------------------------------------------

var formCallback = $('.js-form-callback');
var fieldPhoneCallback = $('.js-form-callback .js-phone');
var fieldPageIdCallback = $('.js-form-callback .page_id');
var submitButton = formCallback.find('.s-submit');
var msgContainerName = ".js-message";
var msgContainer = $(msgContainerName);

formCallback.validate({
    ignore: ":hidden:not(.input--ignore-not)",
    errorClass: "form__error",
    errorContainer: msgContainerName,
    errorElement: "span",
    errorLabelContainer: msgContainerName,

    showErrors: function (errorMap, errorList) {

        if (msgContainer.hasClass("jsCheckSendClass")) {
            msgContainer.html('').removeClass("jsCheckSendClass");
        }

        msgContainer.removeClass("form__alert--success").addClass("form__alert--error");
        this.defaultShowErrors();
    },

    rules: {
        phone: {
            required: true,
            minlength: 10
        }        
    },
    messages: {
        phone: {
            required: "Введите номер телефона.<br>",
            minlength: "Номер телефона должен состоять как минимум из 10-ти символов.<br>"
        }
    },
    submitHandler: function () {
        var predata = {
            'phone': fieldPhoneCallback.val(),
            'id': fieldPageIdCallback.val(),
            'type_form': $(formCallback).attr('data-form-type'),
        };

        $.ajax({
            type: "POST",
            url: "/ajax-handler/",
            data: predata,
            success: function (data, textStatus, jqXHR) {
                var response = $.parseJSON(data);
                if (response) {
                    Swal({
                        type: 'success',
                        title: 'Спасибо!',
                        text: 'Мы вам обязательно позвоним!',
                        confirmButtonClass: 'button button--custom form__button',
                    });

                    $(formCallback).find("input.form__control").val("");
                } else {                    
                    Swal({
                        type: 'error',
                        title: 'Ошибка',
                        text: 'Не удалось отправить сообщение',
                        confirmButtonClass: 'button button--custom form__button',
                    });
                }
            },
            error: function (data, textStatus, errorThrown) {
                Swal({
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось отправить сообщение',
                    confirmButtonClass: 'button button--custom form__button',
                });                
            }
        });
        return false;
    }
});

function showFormAlert(status, msg) {
    var removeStatus = 'error';

    if (status == 'error') {
        removeStatus = 'success';
    }

    msgContainer.removeClass("form__alert--" + removeStatus)
        .html('')
        .addClass("form__alert--" + status)
        .addClass('jsCheckSendClass')
        .html('<span class="form__'+ status +'">' + msg + '</span>')
        .show();
};

function showPopupMsg(popup_title, popup_text, popup_type, popup_btnText, popup_color) {
    swal({
        title: popup_title || "",
        text: popup_text || 'Спасибо! Ваша заявка успешно отправлена',
        type: popup_type || "success",
        confirmButtonText: popup_btnText || "OK",
        confirmButtonColor: popup_color || '#125e0e'
    });
}

function showResponce(response) {
    if (response) {
        Swal({
            type: 'success',
            title: 'Спасибо!',
            text: 'Ваша заявка успешно отправлена!',
            confirmButtonClass: 'button button--custom form__button',
        });
    } else {
        Swal({
            type: 'error',
            title: 'Ошибка',
            text: 'Не удалось отправить сообщение',
            confirmButtonClass: 'button button--custom form__button',
        });
    }
}
