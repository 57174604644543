$('document').ready(function() {

    var stickyHeight = $('.menu--nav').offset().top;
    var stickyWidth = $(window).width();

    $(window).scroll(function(){

        if (stickyWidth > 990) {
            if ($(window).scrollTop() >= stickyHeight) {
                $('.menu--nav').addClass('sticky-header-menu');
                $('.section--header').css({'padding-bottom' : '62px'});
            }
            else {
                $('.menu--nav').removeClass('sticky-header-menu');
                $('.section--header').css({'padding-bottom' : '0px'});
            }
        }
        
    });

});