$(function () {
    ymaps.ready(init);
    var myMapLeft;
    var myMapRight;

    function init() {
        // myMapLeft = new ymaps.Map("map__left", {
        //     center: [56.855871, 35.911708],
        //     controls: [],
        //     zoom: 14
        // });
        // myMapLeft.behaviors.disable('scrollZoom');
        // myMapLeft.behaviors.disable('drag');
        // myMapLeft.controls.add(new ymaps.control.ZoomControl());
        // var myPlacemark = new ymaps.Placemark([56.855871, 35.911708],
        //     {
        //         type: "Point",
        //         hintContent: 'Симеоновская, 39, оф. 105',
        //         balloonContent: 'Симеоновская, 39, оф. 105',
        //         iconCaption: 'Симеоновская, 39, оф. 105'
        //     }, {
        //         iconColor: '#bd2d28'
        //     });


        // myMapLeft.geoObjects.add(myPlacemark);


        myMapRight = new ymaps.Map("map__right", {
            center: [56.857443, 35.912292],
            controls: [],
            zoom: 14
        });
        myMapRight.behaviors.disable('scrollZoom');
        myMapRight.behaviors.disable('drag');
        myMapRight.controls.add(new ymaps.control.ZoomControl());
        var myPlacemark = new ymaps.Placemark([56.857443, 35.912292],
            {
                type: "Point",
                hintContent: 'б-р Радищева, 31',
                balloonContent: 'б-р Радищева, 31',
                iconCaption: 'б-р Радищева, 31'
            }, {
                iconColor: '#bd2d28'
            });


        myMapRight.geoObjects.add(myPlacemark);
    }
});
