$(document).ready(function () {

    $(".js-slider-work").magnificPopup({
        delegate: '.js-popup',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        }
    });

    $(".js-slider-carousel").magnificPopup({
        delegate: '.js-popup',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        }
    });

    $(".js-slider").magnificPopup({
        delegate: '.js-popup',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        }
    });

    $('.js-cart-product__img').magnificPopup({ type: 'image' });

    $(".catalog-item__image").each(function () {
        $(this).magnificPopup({
            delegate: '.js-popup',
            type: 'image',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1]
            }
        });
    });
});