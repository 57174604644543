// mobileMenuToggler.on('click', function (event) {
//   event.preventDefault();
//   var selector = $(this).attr('data-menu');
//   $('.' + selector).slideToggle(500);
//   event.stopPropagation();
// });

// $(document).on('click', function (event) {
//   var width = $(window).width();
//   if (width < 992) {
//     mobileMenu.slideUp(500);
//     mobileMenuToggler.removeClass("active")
//     event.stopPropagation();
//   }
// });

var mobileMenuToggler = $(".js-mobile-menu-toggler");
var mobileMenu = $(".js-menu");
var status = 'hide';

$('.contact-header-mobile-toggler').on('click', function (event) {

    if(status == 'show'){
      $('.contact-header--fon').slideUp('fast');
      status = 'hide';
    } else {
      $('.contact-header--fon').slideDown('fast');
      status = 'show';
    }
});

$(window).resize(function () {
  var width = $(window).width();
  if (width > 991 && mobileMenu.is(':hidden')) {
    mobileMenu.removeAttr('style');
  }

  if (width > 991) {
    $('.contact-header--fon').show();
  }
});