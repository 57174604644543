$(document).ready(function() {

    $("#mobile-menu").mmenu({
        navbar: {
            title: "Меню"
        },     
        "extensions": [
            "pagedim-black",
         ],
         "autoHeight": true,
    });

    $(window).resize(function () {
        var width = $(window).width();
        var mMenu = $("#mobile-menu").data("mmenu");

        if(width > 990) {
            mMenu.close();
        }
    });

 });