

$(document).ready(function () {
	var stamp__sample 		= $(".stamp__sample"); 				//оболочка калькулятора с данными о фикс.ценах
	var sample_price 			= stamp__sample.data("sample");		//фиксированная цена выбранного оттиска
	var time_price 				= stamp__sample.data("time");			//фиксированная цена за ускорение работы
	var shbl_price 				= stamp__sample.data("shablon");			//фиксированная цена за изменение шаблона
	

	var stamp__type_item 		= $(".stamp__type-item");				//тип печати или штампа
	var stamp__sample_list 	= $(".stamp__sample-list");				//обёртка образцов оттиска
	var heading_sample 		= $(".heading--sample");				//заголовок над оттисками
	var chosen_type 			= '';									//выбранный клиентом тип печати
	var stamp__type_name		= '';									//Название выбранной клиентом печати

	var stamp__sample_item 	= $(".stamp__sample-item");			//образец оттиска
	var stamp__fixture_list 		= $(".stamp__fixture-list");				//обёртка остнастки
	var heading_fixture 		= $(".heading--fixture");				//заголовок над остнасткой
	var chosen_sample 			= '';									//выбранный клиентом образец оттиска
	var stamp__sample_name 	= '';									//Название выбранного клиентом оттиска

	var stamp__fixture_item 	= $(".stamp__fixture-item");			//остнастка
	var stamp__info 			= $(".stamp__info");					//обертка формы заказа
	var heading_info 			= $(".heading--info");					//заголовок над формой заказа
	var chosen_fixture 			= '';									//выбранная клиентом останастка
	var fixture_price 			= 0;									//цена выбранной остнаски
	var stamp__fixture_name 	= '';									//Название выбранной клиентом остнастки

	var stamp__result_value 	= $(".stamp__result-value");			//элемент для отображения суммы
	var stamp__form_radio		= $(".stamp__form-radio"); 				//все радиокнопки
	var stamp__radio_pay 		= $(".stamp__form-radio--pay"); 		//радиокнопка способ оплаты
	var stamp__radio_time 		= $(".stamp__form-radio--time"); 		//радиокнопка время выполнения работы
	var stamp__radio_shablon	= $(".stamp__form-radio--shbl"); 		//радиокнопка изменения шаблона
	var pay_type 				= '';									//тип оплаты
	var pay_name 				= 'Онлайн-оплата'; 				//вид оплаты по умолчанию
	var time_val 				= '';									//тип скорости  выполения заказа
	var time_name 				= 'Стандартное время изготовления'; //срочность заказа по умолчанию
	var shablon_val				= '';								//тип изменения шаблона
	var shablon_name 			= 'Строго по шаблону'; 		//изменение шаблона по умолчанию


	var stamp_form 			= $("#stamp_form"); 					//форма заказа
	var stamp__form_fields		= $(".stamp__form-text");				//все поля формы
	var stamp__form_ta_fields	= $(".stamp__form-textarea");			//все textarea поля формы
	var stamp__form_msg		= $(".stamp__form-textarea--msg"); 	// поле сообщения
	var stamp__form_doc		= $(".stamp__form-checkbox--doc"); 	//чекбокс согласия с перечнем документов
	var stamp__form_doc_txt	= $(".stamp__form-doc-text");			//текст согласия с перечнем документов
	var system_msg 			= $(".system-msg"); 					//поле для сообщений валидатора формы

	stamp__type_item.on("click", function(){							//при клике на типе печати:
		var type 				= $(this).data("type");					//получаем данные из элемента (его тип)
		var sample				= $(".stamp__sample-list--" +type); 	//выбираем обертку оттисков нужного нам типа
		var sample_type 		= '';									//тип группы оттиска
		stamp__type_name		= $(this).children(".stamp__type-name").text(); //получаем название выбранной печати
		chosen_type 			= type;									//записываем в глобальную пер.тип штампа

		stamp__type_item.removeClass("stamp__type-item--active"); 		//обнуляем выбранные ранее печати
		stamp__sample_item.removeClass("stamp__sample-item--active"); //у всех оттисков убираем активность
		stamp__fixture_item.removeClass("stamp__fixture-item--active"); //обнуляем активную остнастку
		chosen_sample 	= '';										//обнуляем выбранные оттиски
		stamp__sample_list.hide();										//скрываем показанные ранее оттиски
		stamp__fixture_list.hide();										//скрываем показанные ранее остнастки
		heading_fixture.hide();											//скрываем заголовок остнастки
		stamp__info.hide();												//скрываем форму
		heading_info.hide();											//скрываем аголовок формы
		system_msg.html('');											//очищаем сообщения об ошибках или доставке
		stamp_form.removeClass("type-1 type-2 type-3 type-4 type-5");//ощищаем все классы формирующие форму
		stamp__form_fields.removeClass("field-error");					//очищаем классы для не валидных полей
		stamp__form_ta_fields.removeClass("field-error");				//очищаем классы для не валидных textarea полей
		stamp__form_doc_txt.removeClass("field-chb-error");			//очищаем невалидность checkbox полей
		//stamp__form_radio.removeAttr('checked');					//сбрасываем значения радиокнопок

		$(this).addClass("stamp__type-item--active");					//даем кликнутому типу печати активность
		sample.fadeIn(500);											//отображаем блок оттисков с нужным типом
		heading_sample.show();										//отображаем заголовок перед оттисками

		if(type == "type-1" || type == "type-2" || type == "type-4") 	//если типы печати 1,2,4:
			sample_type = "type-1";									//то тип остнастки будет 1
		else if(type == "type-5")										//если тип печати 5:
			sample_type = "type-2";									//то тип остнастки будет 2
		else if(type == "type-3")										//если тип печати 3:
			sample_type = type; 										//то тип остнастки будет равен типу печати

		var fixture = $(".stamp__fixture-list--" +sample_type);			//находим блок остнастки для печати
		$(this).addClass("stamp__sample-item--active");				//делаем выбранный оттиск активным
		fixture.fadeIn(500);												//отображаем остнастку нужного типа печати
		heading_fixture.show();											//отображаем заголовок для остнастки
		stamp_form.addClass(type);									//добавляем к форме нужный класс
	});

	stamp__sample_item.on("click", function(){						//если кликнули на оттиске:
		chosen_sample = '';											//обнуляем ранее выбранный оттиск в пер.

		if(chosen_type){												//если не пуст выбранный тип печати в пер.
			var sample 			= $(this).data("sample");				//принимаем данные кликнутого оттиска
			chosen_sample 	= sample;								//записываем выбранный тип оттиска в пер.
			stamp__sample_name = $(this).children().children().attr("alt"); //название выбранного оттиска
			stamp__sample_item.removeClass("stamp__sample-item--active");	//обнуляем активные оттиски
			$(this).addClass("stamp__sample-item--active");			//делаем выбранный оттиск активным
		}
	});

	stamp__fixture_item.on("click", function(){							//если кликнули на остнастке
		if(chosen_type && chosen_sample){							//если пер. типа печати и оттиска не пусты
			var fixture 			= $(this).data("fixture");				//получаем данные из выбранной остнаски
			fixture_price 		= Number($(this).data("price")); 		//принимаем цену кликнутой остнастки
			chosen_fixture 		= fixture;								//пишем в пер. выбранную остнастку
			stamp__fixture_name = $(this).children(".stamp__fixture-name").text(); //получаем название выбранной остнастки

			stamp__fixture_item.removeClass("stamp__fixture-item--active"); //обнуляем активные прежде остнастки
			$(this).addClass("stamp__fixture-item--active");			//делаем выбранную остнастку активной
			stamp__info.fadeIn(500);									//отображаем форму заказа
			time_val = $(".stamp__form-radio--time:checked").val(); 	//берём значение выбора выполнения заказа

			if(time_val == "fast")
				stamp__result_value.text(sample_price + fixture_price + time_price); //выводим цену с срочностью
			else
				stamp__result_value.text(sample_price + fixture_price); //выводим цену без срочности

			if(fixture_price){														//получаем все не скрытые поля формы
				stamp__form_phone	= $(".stamp__form-text--phone:visible");	//поле телефон
				stamp__form_email		= $(".stamp__form-text--email:visible");	//поле e-mail
				stamp__form_org		= $(".stamp__form-text--org:visible");		//поле формы организации
				stamp__form_title		= $(".stamp__form-text--title:visible"); 		//поле названия юр.лица
				stamp__form_family	= $(".stamp__form-text--family:visible");	 //поле фамилия
				stamp__form_name		= $(".stamp__form-text--name:visible"); 	//поле имя
				stamp__form_name2	= $(".stamp__form-text--name2:visible"); 	//поле отчество
				stamp__form_inn		= $(".stamp__form-text--inn:visible"); 		//поле ИНН
				stamp__form_ogrn		= $(".stamp__form-text--ogrn:visible"); 	//поле ОГРН
				stamp__form_snils		= $(".stamp__form-text--snils:visible"); 	//поле СНИЛС
				stamp__form_city		= $(".stamp__form-text--city:visible"); 		//поле населённый пункт
				stamp__form_strings	= $(".stamp__form-textarea--strings:visible"); //поле строк для штампа
			}
		}
	});

	stamp__radio_pay.change(function(){								//если изменился вариант оплаты
		pay_type = $(this).val();										//принимаем новое значение

		if(pay_type == "nal")
			pay_name = "Онлайн-оплата";							//пишем тип оплаты в пер.
		else if(pay_type == "beznal")
			pay_name = "Оплата по безналичному расчёту ";			//пишем тип оплаты в пер.

		time_val = $(".stamp__form-radio--time:checked").val(); 		//берём цену времени работы
		shablon_val = $(".stamp__form-radio--shbl:checked").val(); 		//берём изменение шаблона

		if(time_val == "fast"){
			if(shablon_val == "mods") {
				stamp__result_value.text(sample_price + fixture_price +  time_price + shbl_price); //выводим цену				
			}else{
				stamp__result_value.text(sample_price + fixture_price +  time_price); //выводим цену
			}
		}else{
			if(shablon_val == "mods") {
				stamp__result_value.text(sample_price + fixture_price + shbl_price); //выводим цену	
			}else{
				stamp__result_value.text(sample_price + fixture_price ); //выводим цену без срочности
			}			
		} 	
	});

	stamp__radio_time.change(function(){									//если изменилось время работы
		time_val = $(this).val();											//принимаем новое значение
		shablon_val = $(".stamp__form-radio--shbl:checked").val();
		
		if(time_val == "fast"){
			if(shablon_val == "mods") {
				stamp__result_value.text(sample_price + fixture_price +  time_price + shbl_price); //выводим цену				
			}else{
				stamp__result_value.text(sample_price + fixture_price +  time_price); //выводим цену
			}
			time_name = "Срочное изготовление";						//пишем тип срочности в пер.
		}else{
			if(shablon_val == "mods") {
				stamp__result_value.text(sample_price + fixture_price + shbl_price); //выводим цену				
			}else{
				stamp__result_value.text(sample_price + fixture_price ); //выводим цену без срочности
			}	
			time_name = "Стандартное время изготовления";			//пишем тип срочности в пер.			
		} 		
			
	});
	
	stamp__radio_shablon.change(function(){									//если изменилось время работы
		shablon_val = $(this).val();											//принимаем новое значение
		time_val = $(".stamp__form-radio--time:checked").val();
		
		if(time_val == "fast"){
			if(shablon_val == "mods") {
				stamp__result_value.text(sample_price + fixture_price +  time_price + shbl_price); //выводим цену
				shablon_name = "С изменением шаблона";						//пишем тип изменения шаблона.				
			}else{
				stamp__result_value.text(sample_price + fixture_price +  time_price); //выводим цену
				shablon_name = "Строго по шаблону";			//пишем тип изменения шаблона в пер.				
			}
		}else{
			if(shablon_val == "mods") {
				stamp__result_value.text(sample_price + fixture_price + shbl_price); //выводим цену		
				shablon_name = "С изменением шаблона";						//пишем тип изменения шаблона.				
			}else{
				stamp__result_value.text(sample_price + fixture_price ); //выводим цену без срочности
				shablon_name = "Строго по шаблону";			//пишем тип изменения шаблона в пер.
			}				
		} 			
		
	});
	
	var onloadCallback = function() {
		grecaptcha.render('recaptcha', {
			'sitekey' : '6LeG65AUAAAAANpO0u6pKhtAJ2ua6A66vDY8c28h'
		});
    };

	stamp_form.on("submit", function(){									//если нажали кнопку "Отправить заказ"
		stamp__form_fields.removeClass("field-error");						//очищаем классы неавлидных полей
		stamp__form_ta_fields.removeClass("field-error");					//очищаем классы неавлидных textarea полей
		stamp__form_doc_txt.removeClass("field-chb-error");					//очищаем невалидность checkbox полей

		if(stamp__form_doc.prop('checked')){								//если пользователь ознакомился с докум.
			var err = [];
			var post_msg = new Object();

			if(typeof stamp__form_phone.val() != "undefined"){
				if(stamp__form_phone.val() != ''){
					post_msg.stamp__form_phone = stamp__form_phone.val();		//получаем значение поля "орг форма"
				}else{
					err.push("Заполните поле &laquo;Контактный телефон&raquo;.");
					stamp__form_phone.addClass("field-error");
				}
			}
			if(typeof stamp__form_email.val() != "undefined"){
				if(stamp__form_email.val() != ''){
					post_msg.stamp__form_email = stamp__form_email.val();		//получаем значение поля "орг форма"
				}else{
					err.push("Заполните поле &laquo;Электронная почта&raquo;.");
					stamp__form_email.addClass("field-error");
				}
			}
			if(typeof stamp__form_org.val() != "undefined"){
				if(stamp__form_org.val() != ''){
					post_msg.stamp__form_org = stamp__form_org.val();		//получаем значение поля "орг форма"
				}else{
					err.push("Заполните поле &laquo;Организационная форма&raquo;.");
					stamp__form_org.addClass("field-error");
				}
			}
			if(typeof stamp__form_title.val() != "undefined"){
				if(stamp__form_title.val() != ''){
					post_msg.stamp__form_title = stamp__form_title.val(); 	//получаем значение имени юр лица
				}else{
					err.push("Заполните поле &laquo;Название юридического лица&raquo;.");
					stamp__form_title.addClass("field-error");
				}
			}
			if(typeof stamp__form_inn.val() != "undefined"){
				if(stamp__form_inn.val() != ''){
					post_msg.stamp__form_inn = stamp__form_inn.val(); 		//получаем значение ИНН
				}else{
					err.push("Заполните поле &laquo;ИНН&raquo;.");
					stamp__form_inn.addClass("field-error");
				}
			}
			if(typeof stamp__form_ogrn.val() != "undefined"){
				if(stamp__form_ogrn.val() != ''){
					post_msg.stamp__form_ogrn =  stamp__form_ogrn.val();		//получаем значение ОГРН
				}else{
					err.push("Заполните поле &laquo;ОГРН&raquo;.");
					stamp__form_ogrn.addClass("field-error");
				}
			}
			if(typeof stamp__form_city.val() != "undefined"){
				if(stamp__form_city.val() != ''){
					post_msg.stamp__form_city = stamp__form_city.val();		//получаем значение города
				}else{
					err.push("Заполните поле &laquo;Город&raquo;.");
					stamp__form_city.addClass("field-error");
				}
			}
			if(typeof stamp__form_msg.val() != "undefined"){
				if(stamp__form_msg.val() != '')
					post_msg.stamp__form_msg = stamp__form_msg.val();		//получаем текст сообщения
			}
			if(typeof stamp__form_family.val() != "undefined"){
				if(stamp__form_family.val() != ''){
					post_msg.stamp__form_family = stamp__form_family.val(); 	//получаем значение фамилии
				}else{
					err.push("Заполните поле &laquo;Фамилия&raquo;.");
					stamp__form_family.addClass("field-error");
				}
			}
			if(typeof stamp__form_name.val() != "undefined"){
				if(stamp__form_name.val() != ''){
					post_msg.stamp__form_name = stamp__form_name.val(); 	//получаем значение имени
				}else{
					err.push("Заполните поле &laquo;Имя&raquo;.");
					stamp__form_name.addClass("field-error");
				}
			}
			if(typeof stamp__form_name2.val() != "undefined"){
				if(stamp__form_name2.val() != ''){
					post_msg.stamp__form_name2 = stamp__form_name2.val(); 	//получаем значение отчества
				}else{
					err.push("Заполните поле &laquo;Отчество&raquo;.");
					stamp__form_name2.addClass("field-error");
				}
			}
			if(typeof stamp__form_snils.val() != "undefined"){
				if(stamp__form_snils.val() != ''){
					post_msg.stamp__form_snils = stamp__form_snils.val(); 		//получаем значение СНИЛС
				}else{
					err.push("Заполните поле &laquo;СНИЛС&raquo;.");
					stamp__form_snils.addClass("field-error");
				}
			}
			if(typeof stamp__form_strings.val() != "undefined"){
				if(stamp__form_strings.val() != ''){
					post_msg.stamp__form_strings = stamp__form_strings.val(); 	//получаем значение строк для штампа
				}else{
					err.push("Заполните поле &laquo;Строки для штампа&raquo;.");
					stamp__form_strings.addClass("field-error");
				}
			}

			post_msg.stamp__type_name = stamp__type_name;					        //записывавем название типа печати
			post_msg.stamp__sample_name = stamp__sample_name;				        //записывавем название типа оттиска
			post_msg.stamp__fixture_name = stamp__fixture_name;				        //записывавем название типа остнастки
			post_msg.pay_name = pay_name;									        //записывавем название типа оплаты
			post_msg.time_name = time_name;									        //записывавем название срока изготовления
			post_msg.shbl_name = shablon_name;								        //записываем название изменения шаблона
			post_msg.stamp__result_value = stamp__result_value.text();		        //записывавем значение стоимости
            post_msg.captcha_string = grecaptcha.getResponse();	                    //записывавем значение ключа капчи
            



			post_msg.stamp_type = chosen_type;									    //передаем тип выбранной печати
			if(err.length){														    //если ошибки есть то:
				var text = '';
				for(var i = 0; i < err.length; i++){
					text = text + err[i] + "<br>";								    //выводим ошибки в поле для сообщений
				}
				system_msg.html("<p class='alert alert--error'>" + text + "</p>");
			}else{
				stamp__form_fields.removeClass("field-error");					    //очищаем невалидность полей
				stamp__form_ta_fields.removeClass("field-error");				    //очищаем невалидность textarea полей
				stamp__form_doc_txt.removeClass("field-chb-error");				    //очищаем невалидность checkbox полей
				// smtp mail

				$.ajax({
					url: "/ajax-handler/", 					                        // куда отправляем
					type: "post", 												    // метод передачи
					data: $.param(post_msg),
					success: function(data) {
						
						if (data) {
							Swal({
								type: 'success',
								title: 'Спасибо!',
								text: 'Ваш заказ успешно отправлен!',
								confirmButtonClass: 'button button--custom form__button',
							});
						}

						system_msg.html(data);
						grecaptcha.reset();
					},
					error: function() {

						Swal({
							type: 'error',
							title: 'Ошибка',
							text: 'Не удалось отправить ваш заказ.',
							confirmButtonClass: 'button button--custom form__button',
						});
					}
				});
			}
		}else{
			system_msg.html("<p class='alert alert--error'>Ознакомьтесь, пожалуйста, со списком документов.</p>");
			stamp__form_doc_txt.addClass("field-chb-error");
		}

		return false;

	});
});