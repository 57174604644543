$(function() {
    $(".js-slider").slick({
        dots: true,
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow.svg">',
        nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow.svg">',
        slidesToShow: 1,
        adaptiveHeight: true,
        slidesToScroll: 1,
        centerPadding: "5px",        
    });

    $(".js-slider-reviews").slick({
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow.svg">',
        nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow.svg">',
        slidesToShow: 1,
        adaptiveHeight: true,
        slidesToScroll: 1,
        centerPadding: "5px",        
    });    

    $(".js-slider-client").slick({
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow.svg">',
        nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow.svg">',
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    
    $(".js-slider-other-product ").slick({
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow-light.svg">',
        nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow-light.svg">',
        slidesToShow: 4,
        // slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

	$(".js-slider-work").slick({
		infinite: true,
		arrows: true,
		prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow.svg">',
		nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow.svg">',
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 1
				}
			}
		]
    });
    
    $(".js-slider-carousel").slick({
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow.svg">',
        nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow.svg">',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    
    $(".js-big-slider").slick({
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev-big" src="/site/templates/static/img/slider/arrow.svg">',
        nextArrow: '<img class="slick-next-big" src="/site/templates/static/img/slider/arrow.svg">',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    });

});
